import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'

const classNames = require('classnames')

class CustomSelectedFilter extends React.Component {
  render() {
    const { bemBlocks, labelValue, filterId, removeFilter, texts } = this.props

    const classesNamesContainer = {}
    classesNamesContainer[bemBlocks.option()] = true
    classesNamesContainer[bemBlocks.container('item')] = true
    classesNamesContainer[`selected-filter--${filterId}`] = true

    return (
      <div className={classNames(classesNamesContainer)}>
        <div className={bemBlocks.option('name')}>{labelValue}</div>
        <button
          type="button"
          className={bemBlocks.option('remove-action')}
          onClick={removeFilter}
          onKeyDown={removeFilter}
          title={texts.remove_filter}
        >
          <i
            className={classNames({
              close_icon: true,
            })}
          />
        </button>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CustomSelectedFilter)
