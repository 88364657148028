import React from 'react'
import { connect } from 'react-redux'

import hitsViewMap from './hitsViewMap'
import * as actionCreators from '../../../store/actions'

import ListViewIcon from '../../../assets/svg/list-view-icon.svg'
import ListViewIconSelected from '../../../assets/svg/hits-view-list-selected.svg'
import GridViewIcon from '../../../assets/svg/grid-view-icon.svg'
import GridViewIconSelected from '../../../assets/svg/hist-view-grid-selected.svg'

const classNames = require('classnames')

export class HitsViewSwitcher extends React.Component {
  render() {
    const { search, updateHitsView } = this.props

    return (
      <div className={classNames({ search_hits_view_switcher: true })}>
        <div
          className={classNames({
            hits_view_btn: true,
            hits_view_grid: true,
            hits_view_selected: search.hitsView === hitsViewMap.grid,
            theme_background_color: search.hitsView === hitsViewMap.grid,
            theme_svg: !(search.hitsView === hitsViewMap.grid),
          })}
          role="button"
          tabIndex={0}
          onClick={() => {
            updateHitsView(hitsViewMap.grid)
          }}
          onKeyPress={() => {
            updateHitsView(hitsViewMap.grid)
          }}
        >
          {search.hitsView === hitsViewMap.grid && <GridViewIconSelected />}
          {search.hitsView === hitsViewMap.list && <GridViewIcon />}
        </div>
        <div
          className={classNames({
            hits_view_btn: true,
            hits_view_list: true,
            hits_view_selected: search.hitsView === hitsViewMap.list,
            theme_background_color: !(search.hitsView === hitsViewMap.grid),
            theme_svg: search.hitsView === hitsViewMap.grid,
          })}
          role="button"
          tabIndex={0}
          onClick={() => {
            updateHitsView(hitsViewMap.list)
          }}
          onKeyPress={() => {
            updateHitsView(hitsViewMap.list)
          }}
        >
          {search.hitsView === hitsViewMap.list && <ListViewIconSelected />}
          {search.hitsView === hitsViewMap.grid && <ListViewIcon />}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    search: state.search,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(HitsViewSwitcher)
