import React from 'react'
import { connect } from 'react-redux'

import {
  SearchkitComponent,
  SearchBox,
  SearchkitProvider,
  Hits,
  NoHits,
  Pagination,
  SelectedFilters,
  HitsStats,
} from 'searchkit'
import CustomHitsStats from './_parts/hitsStats'
import * as actionCreators from '../../store/actions'

import HitItem from './_parts/hitItem'
import HitListItem from './_parts/hitListItem'
import OfflineItems from './_parts/offlineItems'

import HitsViewSwitcher from './_parts/hitsViewSwitcher'
import SearchSort from './_parts/sort'
import SearchFilter from './_parts/filter'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import PaginationComponent from './_parts/paginationComponent'

import './styles.scss'
import 'searchkit/theming/theme.scss'
import hitsViewMap from './_parts/hitsViewMap'
import CustomSelectedFilter from './_parts/selectedFilter'
import { customQueryBuilder } from './common/utils'
import searchContextMap from './_parts/searchContextMap'

const classNames = require('classnames')

let deviceWasOffline = false

export class SearchResults extends SearchkitComponent {
  executeSearchKitInitIfNeeded = () => {
    const { initializeSearchkitManager, authentication, search } = this.props
    const { user } = authentication
    const { context } = search

    if (context !== searchContextMap.products) {
      initializeSearchkitManager('valueable/', user, searchContextMap.products)
    }
  }

  componentDidMount = async () => {
    this.executeSearchKitInitIfNeeded()
  }

  componentWillReceiveProps = async nextProps => {
    const { device, search } = this.props
    if (!deviceWasOffline && device.isOnline) {
      this.executeSearchKitInitIfNeeded()
    }
    deviceWasOffline = device.isOnline

    if (nextProps.search && search && nextProps.search.lastRemovedItem !== search.lastRemovedItem) {
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    }
  }

  render() {
    const { device, searchkit, search, texts } = this.props

    // const pageSize = device.screenType === screenTypesMap.iPadV ? 9 : 8
    const pageSize = 72
    const devicePadding = device.screenType === screenTypesMap.iPadV ? 0 : 60
    const itemsPerRow = typeof window !== 'undefined' ? Math.floor((window.innerWidth - devicePadding) / 245) : 4
    const containerWidth = `${245 * itemsPerRow + 10 * itemsPerRow}px`

    const NoHitsDisplay = () => {
      return (
        <div
          className={classNames({
            search_no_hits: true,
          })}
        >
          {texts.search_no_result}
        </div>
      )
    }

    const NoHitsErrorDisplay = () => {
      return (
        <div
          className={classNames({
            search_no_hits: true,
          })}
        >
          {texts.search_error}
        </div>
      )
    }

    return (
      <div>
        {device.isOnline && searchkit !== null && (
          <SearchkitProvider searchkit={searchkit}>
            <div
              className={classNames({
                search_main_container: true,
              })}
            >
              <div
                className={classNames({
                  search_header: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <SearchBox searchOnChange queryBuilder={customQueryBuilder} />
                <div className={classNames({ search_header_actions: true })}>
                  <SearchSort />
                  <HitsViewSwitcher />
                  <SearchFilter />
                </div>
              </div>
              <div
                className={classNames({
                  search_results_container: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
                style={{ width: containerWidth }}
              >
                <div
                  className={classNames({
                    search_info_bar: true,
                  })}
                >
                  <SelectedFilters itemComponent={CustomSelectedFilter} />
                  <HitsStats component={CustomHitsStats} />
                </div>

                <Hits
                  hitsPerPage={pageSize}
                  mod="sk-hits-grid"
                  itemComponent={search.hitsView === hitsViewMap.grid ? HitItem : HitListItem}
                />

                <NoHits component={NoHitsDisplay} errorComponent={NoHitsErrorDisplay} />

                <Pagination showNumbers showLast pageScope={4} listComponent={PaginationComponent} />
              </div>
            </div>
          </SearchkitProvider>
        )}
        {!device.isOnline && (
          <div>
            <OfflineItems containerWidth={containerWidth} />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
    device: state.device,
    searchkit: state.search.searchkit,
    search: state.search,
  }
}

export default connect(mapStateToProps, actionCreators)(SearchResults)
