import React from 'react'
import { connect } from 'react-redux'

import { Link } from 'gatsby'
import * as actionCreators from '../../../../store/actions'

import reportPageMap from '../../helper/reportPageMap'
import ScoringModeMap from '../../../scoring_tree/helper/scoringModeMap'

export class ReportLinks extends React.Component {
  render() {
    const { texts, scoringTree, linkClass, productId, authentication } = this.props

    // Temporary condition
    const reportDetailedEnabled =
      process.env.reportDetailedEnabled !== undefined ? JSON.parse(process.env.reportDetailedEnabled) : false

    const id = productId || scoringTree.scoring.id

    return (
      <>
        {JSON.parse(process.env.reportEnabled) === true && (
          <>
            {authentication.user.parsedPermissions.canCreateReport && (
              <li className={`report-link ${linkClass}`}>
                <span
                  className="action_with_icon_summary_report"
                  role="button"
                  tabIndex={0}
                  onClick={e => e.stopPropagation()}
                  onKeyPress={e => e.stopPropagation()}
                >
                  <Link to={`/report?id=${id}&view=${reportPageMap.summaryOnly}`}>{texts.report_summary}</Link>
                </span>
              </li>
            )}

            {authentication.user.parsedPermissions.canCreateReport && reportDetailedEnabled && (
              <li className={`report-link ${linkClass}`}>
                <span
                  className="action_with_icon_detailed_report"
                  role="button"
                  tabIndex={0}
                  onClick={e => e.stopPropagation()}
                  onKeyPress={e => e.stopPropagation()}
                >
                  <Link to={`/report?id=${id}&view=${reportPageMap.summary}`}>{texts.report_detail}</Link>
                </span>
              </li>
            )}

            <li className={`report-link ${linkClass}`}>
              <span
                className="action_with_icon_published_report"
                role="button"
                tabIndex={0}
                onClick={e => e.stopPropagation()}
                onKeyPress={e => e.stopPropagation()}
              >
                <a href={`/scoring?id=${id}&view=${ScoringModeMap.clipboard}`}>{texts.report_published}</a>
              </span>
            </li>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportLinks)
