import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import ScoreCharts from './scoreCharts'

import AuthIcon from '../../../assets/svg/auth-icon.svg'
import TemplateIcon from '../../../assets/svg/template-icon.svg'
import EditIcon from '../../../assets/svg/edit-scoring-icon.svg'
import EyeIcon from '../../../assets/svg/eye.svg'
import CheckIcon from '../../../assets/svg/item-check-icon.svg'
import MenuClearIcon from '../../../assets/svg/action-menu-clear-icon.svg'
import DotsBtn from '../../../assets/svg/scoring-head-dots.svg'
import LockedIcon from '../../../assets/svg/lock_white_preview.svg'

import ScoringModeMap from '../../scoring_tree/helper/scoringModeMap'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'

import { deleteScoring } from '../../../../va-corejs-v3/actions/scoring'

import apiEndPoints from '../../../../va-corejs-v3/api/apiEndPoints'

import msgBoxMessageMap from '../../../../va-corejs-v3/utils/msgBoxMessagesMap'
import { getMediaBlobUrl } from '../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class HitListItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      clearActionsVisibile: false,
      actionsSubMenuVisible: false,
      coverImageUrl: '',
      brandMediaUrl: '',
    }
  }

  componentDidMount = async () => {
    const { result } = this.props
    const item = result._source

    if (item && item.cover) {
      this.setState({
        coverImageUrl: item.cover.startsWith('data:') ? item.cover : await getMediaBlobUrl(item.cover),
        brandMediaUrl: item.props.brand.media ? await getMediaBlobUrl(item.props.brand.media) : '',
      })
    }
  }

  pad = d => {
    return String(`0${d}`).slice(-2)
  }

  handleGoToProduct = (e, id, view) => {
    e.stopPropagation()
    const { device, authentication, scoringTree } = this.props

    const productIsLocked = scoringTree.scoring.is_locked

    view =
      view === 0 &&
      !authentication.user.parsedPermissions.canEditProduct &&
      !productIsLocked &&
      authentication.user.parsedPermissions.canViewProduct &&
      !authentication.user.parsedPermissions.canViewProductScoring
        ? 1
        : view

    if (device.isOnline || (!device.isOnline && view === ScoringModeMap.scoring)) {
      const redirectTo = `/scoring?id=${id}&view=${view}`

      if (typeof window !== 'undefined') {
        window.location.href = redirectTo
      }
    }
  }

  handleOpenCloseActionsMenuClicked = e => {
    e.stopPropagation()
    const { actionsSubMenuVisible } = this.state
    this.setState({ actionsSubMenuVisible: !actionsSubMenuVisible })
  }

  handleCheckItemClicked = e => {
    e.stopPropagation()
    const { clearActionsVisibile } = this.state

    this.setState({ clearActionsVisibile: !clearActionsVisibile })
  }

  performDeleteActionOnClientSide = async id => {
    const { device, downloaded, removeDownloadedScorings, updateOfflineProductsList } = this.props

    await removeDownloadedScorings(downloaded.scorings, id)
    if (!device.isOnline) {
      updateOfflineProductsList()
    }
  }

  handleClearButtonClicked = async (e, id) => {
    e.stopPropagation()
    this.setState({ clearActionsVisibile: false })
    await this.performDeleteActionOnClientSide(id)
  }

  handleDownloadItemClicked = e => {
    e.stopPropagation()
    const { downloadActionsVisibile } = this.state

    this.setState({ downloadActionsVisibile: !downloadActionsVisibile })
  }

  handleDuplicateButtonClicked = (e, id, name) => {
    e.stopPropagation()

    const { updateScoringDuplicateProps } = this.props
    this.setState({ actionsSubMenuVisible: false })

    updateScoringDuplicateProps(true, id, name)
  }

  handleRemoveProductClicked = async (e, id) => {
    e.stopPropagation()

    const { updateLastRemovedItem } = this.props

    await this.performDeleteActionOnClientSide(id)
    await deleteScoring(id)
    updateLastRemovedItem(id)

    this.setState({ actionsSubMenuVisible: false })
  }

  handleExport = (e, scoringId) => {
    const { updateExportData } = this.props
    e.stopPropagation()
    updateExportData(apiEndPoints.export_full_product.replace('{scoringId}', scoringId))
  }

  handleLockUnlockProduct = (e, item) => {
    e.stopPropagation()
    const { updateMsgboxMessage } = this.props
    updateMsgboxMessage(msgBoxMessageMap.confirmLockUnlockScoring, {
      item,
      par1: `${item.props.brand.value} ${item.props.model.value}`,
    })
    this.setState({ actionsSubMenuVisible: false })
  }

  render() {
    const { authentication, result, texts, device, downloaded } = this.props
    const { clearActionsVisibile, actionsSubMenuVisible, coverImageUrl, brandMediaUrl } = this.state
    const item = result._source

    const created = new Date(item.created_at)
    const formattedDate = `${this.pad(created.getDate())}.${this.pad(created.getMonth() + 1)}.${created.getFullYear()}`

    const isDownloaded = !!(downloaded.scorings[item.id] && downloaded.scorings[item.id] === true)

    const userRoles = authentication.user.roles
    const userRole = userRoles.length > 0 ? userRoles[0].slug : 'reader'

    const productIsLocked = item.is_locked

    return (
      <div
        className={classNames({
          search_list_item: true,
          search_list_item_with_menu_active: actionsSubMenuVisible || clearActionsVisibile,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        {productIsLocked && (
          <div
            className={classNames({
              lock_icon_container: true,
            })}
          >
            <div
              className={classNames({
                lock_icon: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <LockedIcon />
            </div>
          </div>
        )}
        <div
          className={classNames({
            search_list_item_head_placeholder: true,
            placeholder_image: true,
          })}
        >
          <div
            className={classNames({
              search_list_item_head: true,
              search_list_item_head_offline: !device.isOnline,
            })}
            style={{
              backgroundImage: `url(${`${coverImageUrl}`})`,
            }}
            role="button"
            tabIndex={0}
            onClick={e => {
              this.handleGoToProduct(e, item.id, ScoringModeMap.product)
            }}
            onKeyPress={e => {
              this.handleGoToProduct(e, item.id, ScoringModeMap.product)
            }}
          />
        </div>

        <div
          className={classNames({
            search_list_item_brand_logo_container: true,
          })}
        >
          {item.props.brand.media && (
            <img className={classNames({ search_list_item_brand_logo: true })} src={brandMediaUrl} alt="brand-logo" />
          )}
          {!item.props.brand.media && (
            <div
              className={classNames({
                search_list_item_brand_logo_placeholder: true,
              })}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <circle
                  cx="609"
                  cy="222"
                  r="14.5"
                  fill="#D8D8D8"
                  fillRule="evenodd"
                  stroke="#000"
                  strokeOpacity=".1"
                  transform="translate(-594 -207)"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={classNames({ search_list_item_brand_data: true })}>
          <div className={classNames({ search_list_item_title: true })}>
            <span>{item.props.brand.value}</span>
            <span>{item.props.model.value}</span>
          </div>
          {item.props.level && (
            <div className={classNames({ search_list_item_subtitle: true })}>{item.props.level.value}</div>
          )}
        </div>
        {item.score_panel && item.score_panel.length > 0 && <ScoreCharts sPanel={item.score_panel} />}

        <div
          className={classNames({
            search_list_item_info: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          <div
            className={classNames({
              search_list_item_template: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <TemplateIcon />
            <span>{item.template.name}</span>
          </div>
          <div
            className={classNames({
              search_list_item_author_container: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <div className={classNames({ search_list_item_author_icon: true })}>
              <AuthIcon />
            </div>
            <div className={classNames({ search_list_item_author_info: true })}>
              <div className={classNames({ search_list_item_author_name: true })}>{item.author.name}</div>
              <div
                className={classNames({
                  search_list_item_creation_date: true,
                })}
              >
                {formattedDate}
              </div>
            </div>
          </div>
          <div
            className={classNames({
              search_list_item_actions: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <div
              className={classNames({
                search_list_item_action_edit: true,
                theme_svg: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => {
                this.handleGoToProduct(e, item.id, ScoringModeMap.scoring)
              }}
              onKeyPress={e => {
                this.handleGoToProduct(e, item.id, ScoringModeMap.scoring)
              }}
            >
              {authentication.user.parsedPermissions.canEditProduct &&
                !productIsLocked &&
                authentication.user.parsedPermissions.canViewProductScoring && <EditIcon />}
              {authentication.user.parsedPermissions.canViewProduct &&
                (productIsLocked || !authentication.user.parsedPermissions.canEditProduct) && <EyeIcon />}
            </div>
          </div>
        </div>
        {userRole.indexOf('reader') === -1 && (
          <div className={classNames({ search_list_item_menu_actions: true })}>
            <div
              className={classNames({
                search_list_item_action_bnt: true,
                search_list_item_actions_menu_bnt: true,
                search_list_item_actions_menu_bnt_active: actionsSubMenuVisible,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
              role="button"
              tabIndex={0}
              onClick={e => {
                this.handleOpenCloseActionsMenuClicked(e)
              }}
              onKeyPress={e => {
                this.handleOpenCloseActionsMenuClicked(e)
              }}
            >
              <DotsBtn />
            </div>
            {actionsSubMenuVisible && (
              <>
                <div
                  className={classNames({
                    transparent_back_layer: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={e => {
                    this.handleOpenCloseActionsMenuClicked(e)
                  }}
                  onKeyPress={e => {
                    this.handleOpenCloseActionsMenuClicked(e)
                  }}
                />
                <div
                  className={classNames({
                    search_list_item_actions_list_container: true,
                  })}
                >
                  <ul
                    className={classNames({
                      search_list_item_actions_list: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    })}
                  >
                    {/* Temporarily disabled since this function is not currently enabled
                  <li
                    className={classNames({
                      search_list_item_action_element: true,
                    })}
                  >
                    <span
                      className={classNames({
                        action_with_icon_attach_documents: true,
                      })}
                    >
                      {texts.scoring_actions_attach_documents}
                    </span>
                  </li>
                  */}
                    <li
                      className={classNames({
                        search_list_item_action_element: true,
                      })}
                    >
                      <span
                        className={classNames({
                          action_with_icon_duplicate: true,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                          this.handleDuplicateButtonClicked(
                            e,
                            item.id,
                            `${item.props.brand.value} ${item.props.model.value}`
                          )
                        }}
                        onKeyPress={e => {
                          this.handleDuplicateButtonClicked(
                            e,
                            item.id,
                            `${item.props.brand.value} ${item.props.model.value}`
                          )
                        }}
                      >
                        {texts.scoring_actions_duplicate}
                      </span>
                    </li>
                    <li
                      className={classNames({
                        search_list_item_action_element: true,
                      })}
                    >
                      <span
                        className={classNames({
                          action_with_icon_export_xls: true,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={e => {
                          this.handleExport(e, item.id)
                        }}
                        onKeyPress={e => {
                          this.handleExport(e, item.id)
                        }}
                      >
                        {texts.scoring_actions_export_xls}
                      </span>
                    </li>
                    {authentication.user.parsedPermissions.canLockProduct && (
                      <li
                        className={classNames({
                          search_list_item_action_element: true,
                        })}
                      >
                        <span
                          className={classNames({
                            action_with_icon_lock_scoring: true,
                          })}
                          role="button"
                          tabIndex={0}
                          onClick={e => {
                            this.handleLockUnlockProduct(e, item)
                          }}
                          onKeyPress={e => {
                            this.handleLockUnlockProduct(e, item)
                          }}
                        >
                          {productIsLocked ? texts.scoring_unlock : texts.scoring_lock}
                        </span>
                      </li>
                    )}
                    {device.isOnline && authentication.user.parsedPermissions.canDeleteProduct && (
                      <li
                        className={classNames({
                          search_list_item_action_element: true,
                        })}
                      >
                        <span
                          className={classNames({
                            action_with_icon_delete: true,
                            theme_color: true,
                          })}
                          role="button"
                          tabIndex={0}
                          onClick={e => {
                            this.handleRemoveProductClicked(e, item.id)
                          }}
                          onKeyPress={e => {
                            this.handleRemoveProductClicked(e, item.id)
                          }}
                        >
                          {texts.delete}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </>
            )}
            {isDownloaded && (
              <>
                <div
                  className={classNames({
                    search_list_item_action_bnt: true,
                    search_list_item_action_bnt_check: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={e => {
                    this.handleCheckItemClicked(e)
                  }}
                  onKeyPress={e => {
                    this.handleCheckItemClicked(e)
                  }}
                >
                  <CheckIcon />
                </div>
                {clearActionsVisibile && (
                  <>
                    <div
                      className={classNames({
                        transparent_back_layer: true,
                      })}
                      role="button"
                      tabIndex={0}
                      onClick={e => {
                        this.handleCheckItemClicked(e)
                      }}
                      onKeyPress={e => {
                        this.handleCheckItemClicked(e)
                      }}
                    />
                    <div
                      className={classNames({
                        search_action_submenu: true,
                        isSafari: device.isSafari,
                        isFirefox: device.isFirefox,
                        desk: device.screenType === screenTypesMap.desk,
                        ipadh: device.screenType === screenTypesMap.iPadH,
                        ipadv: device.screenType === screenTypesMap.iPadV,
                        iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                        iphonese: device.screenType === screenTypesMap.iPhoneSE,
                      })}
                      role="button"
                      tabIndex={0}
                      onClick={e => {
                        this.handleClearButtonClicked(e, item.id)
                      }}
                      onKeyPress={e => {
                        this.handleClearButtonClicked(e, item.id)
                      }}
                    >
                      <div className={classNames({ search_submenu_label: true })}>{texts.clear_from_device}</div>
                      <div className={classNames({ search_submenu_icon: true })}>
                        <MenuClearIcon />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    downloaded: state.downloaded,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    search: state.search,
  }
}

export default connect(mapStateToProps, actionCreators)(HitListItem)
