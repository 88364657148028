import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'
import ScorePanel from '../../commons/score_panel'
import { isProjectModeEnabled } from '../../scoring_tree/_parts/projectMode/utils'

const classNames = require('classnames')

// const uuidv4 = require('uuid/v4')

export class ScoreCharts extends React.Component {
  render() {
    const { sPanel, device, environment, projectMode } = this.props
    const type = isProjectModeEnabled({ project_mode: projectMode }) ? 'double' : process.env.scorePanelType

    return (
      <div
        className={classNames({
          search_item_score_charts: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div className={classNames({ search_item_score_charts_body: true })}>
          {sPanel && (
            <ScorePanel
              scorePanel={sPanel}
              size="compact"
              type={type}
              maxProductScore={environment.config.max_product_score}
              projectMode={isProjectModeEnabled({ project_mode: projectMode })}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoreCharts)
