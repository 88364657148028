import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import HitItem from './hitItem'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'

const uuidv4 = require('uuid/v4')
const classNames = require('classnames')

export class OfflineItems extends React.Component {
  componentDidMount = () => {
    const { updateOfflineProductsList } = this.props
    updateOfflineProductsList()
  }

  render() {
    const { offlineScoringsList, device, texts, containerWidth } = this.props
    const { list } = offlineScoringsList

    return (
      <div className={classNames({ offline_products: true })}>
        <div
          className={classNames({
            search_results_container: true,
            offline_version: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
          style={{ width: containerWidth }}
        >
          {list.length > 0 && (
            <div className="sk-hits-grid">
              {list.map(item => {
                return <HitItem key={uuidv4()} result={item} />
              })}
            </div>
          )}
          {list.length === 0 && (
            <div
              className={classNames({
                search_no_hits: true,
              })}
            >
              {texts.search_offline_no_items}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    offlineScoringsList: state.offlineScoringsList,
  }
}

export default connect(mapStateToProps, actionCreators)(OfflineItems)
