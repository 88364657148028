import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'

class CustomHitsStats extends React.Component {
  render() {
    const { bemBlocks, hitsCount, texts } = this.props
    const resultLabel = hitsCount === 1 ? texts.result : texts.results

    return (
      <div className={bemBlocks.container()} data-qa="hits-stats">
        <div className={bemBlocks.container('info')} data-qa="info">
          {hitsCount} {resultLabel}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CustomHitsStats)
